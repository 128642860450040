<template>
  <mdb-container fluid>
    <mdb-modal :show="openModal" @close="openModal = false">
      <mdb-modal-header>Tell us what is wrong with the order:</mdb-modal-header>
      <mdb-modal-body>
        <mdb-input type="textarea" v-model="textareaDelivery"></mdb-input>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click="openModal=false">Cancel</mdb-btn>
        <mdb-btn color="success" v-on:click="sendClientResult(false)">Make The person do the work again</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="showModal" @close="showModal = false" info size="md">
      <mdb-modal-body>
        <form action="" v-on:submit="submitForm">
          <mdb-input
            class="mb-0 w-100"
            type="text"
            v-model="clientName"
            label="Full Name"
            required
          ></mdb-input>
          <mdb-input
            class="mb-0 w-100"
            type="email"
            v-model="clientEmail"
            label="Email"
            required
          ></mdb-input>
          <mdb-btn color="success" type="submit">
            Continue
          </mdb-btn>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <mdb-row v-if="error" class="justify-content-around pt-3" @click="error = null">
      <mdb-col md="6">
        <mdb-alert color="danger" >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <div v-for="(item, index) in taskDeliveryContent" :key="index">
      <photo-review-panel v-if="item" :task-id="$route.params.taskId ?? $route.params.ident" :deliveryIndex="index" :is-client-review="true" class="mt-3"
        @error="error = $event" @change="error = null">
      </photo-review-panel>
    </div>
    <!-- <photo-review-panel v-if="taskDeliveryContent" :task-id="$route.params.ident" :is-client-review="true" class="mt-3"
      @error="error = $event" @change="error = null">
    </photo-review-panel> -->
    <mdb-container fluid v-if="!loading && showReviewButtons && !reviewed">
      <mdb-row class="mt-3">
        <mdb-col md="6">
          <h4>Is this order ok? </h4>
          <mdb-btn color="green" @click="sendClientResult(true)">Yes</mdb-btn>
          <mdb-btn color="red" @click="openModal = true">No</mdb-btn>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <mdb-row v-if="message" class="justify-content-around pt-3" @click="message = null">
      <mdb-col md="6">
        <mdb-alert color="primary" >{{message}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <div class="hover-load" v-if="loading">
      <mdb-spinner multicolor />
    </div>
  </mdb-container>
</template>

<script>
import {
 mdbAlert, mdbContainer, mdbCol, mdbRow, mdbBtn, mdbSpinner, mdbInput, mdbModal, mdbModalHeader, mdbModalBody,
} from 'mdbvue';
import PhotoReviewPanel from '@/components/PhotoReviewPanel.vue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
 mdbCol, mdbAlert, mdbContainer, mdbRow, PhotoReviewPanel, mdbBtn, mdbSpinner, mdbInput, mdbModal, mdbModalHeader, mdbModalBody,
},
  data() {
    return {
      loading: false,
      error: null,
      task: null,
      showReviewButtons: false,
      message: null,
      reviewed: false,
      openModal: false,
      textareaDelivery: null,
      showModal: false,
      clientName: null,
      clientEmail: null,
    };
  },
  computed: {
    taskDeliveryContent() {
      if (!this.task) {
        return [];
      }

      let deliveryContent = null;

      if (this.task.spotcheckof) {
        deliveryContent = this.task.spotcheckof.deliverycontent;
      } else {
        deliveryContent = this.task.deliverycontent;
      }

      let taskDeliveryContent = deliveryContent;

      const newTaskDeliveryContent = [];
      deliveryContent.forEach((deliveryElement, index) => {
        if (deliveryElement?.type === 'image-file' || (deliveryElement?.type === 'zip' && this.task.photosFromZipFile)) {
          newTaskDeliveryContent.push({
            deliveryIndex: index,
            deliveryContent: deliveryElement,
          });
        }
      });

      taskDeliveryContent = newTaskDeliveryContent;

      return taskDeliveryContent.length > 0 ? taskDeliveryContent : [];
    },
  },
  methods: {
    getInfos(taskId) {
      const apiCall = new Apicall();

      this.loading = true;
      let next = false;
      let taskid = null;
      if (this.$route.params.next) {
        next = true;
      }
      if (this.$route.params.taskid) {
        taskid = this.$route.params.taskid;
      }

      apiCall
        .call('POST', 'api/task-review/infos', { taskid: taskId, next, itemid: taskid })
        .then((response) => {
          this.loading = false;
          if (response.status === 'OK') {
            this.task = response.data;
            this.showReviewButtons = response.complete.showReviewButtons;
          } else {
            this.$emit('error', response.msg);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit('error', error);
        });
    },
    sendClientResult(isOrderOk) {
      const apiCall = new Apicall();
      apiCall
        .call('POST', 'api/task-review/send-client-result', {
          isTaskOk: isOrderOk, taskId: this.$route.params.taskid, commentFromClient: this.textareaDelivery, link: this.$route.fullPath,
        })
        .then((response) => {
          if (response.status === 'OK') {
            if (isOrderOk) {
              this.message = 'We will continue with the order';
              this.reviewed = true;
              this.openModal = false;
            } else {
              this.message = 'The message has been sent to the employee';
              this.reviewed = true;
              this.openModal = false;
            }
          } else {
            this.error = response.msg;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error = error;
        });
    },
    submitForm(event) {
      event.preventDefault();
      if (this.clientName && this.clientEmail) {
        localStorage.setItem('clientName', this.clientName);
        localStorage.setItem('clientEmail', this.clientEmail);
        this.showModal = false;
      }
    },
  },
  mounted() {
    if (this.$route.params.ident) {
      this.getInfos(this.$route.params.ident);
    }
    const storedName = localStorage.getItem('clientName');
    const storedEmail = localStorage.getItem('clientEmail');

    if (storedName && storedEmail) {
      this.clientName = storedName;
      this.clientEmail = storedEmail;
    }

    if (!storedName && !storedEmail) {
        this.showModal = true;
    }
  },
};
</script>

<style scoped>
  .hover-load {
    position: absolute;
    top: 50%;
    left: 50%;
  }
</style>
